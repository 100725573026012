import React from 'react';
import PropTypes from 'prop-types';
import {Container, Highlight, Options} from './styled';


export const NavTabs = ({activeTab, mode, onChangeActiveTab}) => {

  return (
    <Container mode={mode}>
      <Highlight activeTab={activeTab}/>
      {
        tabs.map((tab, index) =>
          <Options key={index} onClick={() => onChangeActiveTab(tab)}>{tab}</Options>
        )
      }
    </Container>
  );
};

// let tabs = ['all', 'about', 'projects', 'media']
let tabs = ['all', 'about', 'projects',]

NavTabs.propTypes = {
  // activeTab: PropTypes.oneOf(['all', 'about', 'projects', 'media']).isRequired,
  activeTab: PropTypes.oneOf(['all', 'about', 'projects']).isRequired,
  mode: PropTypes.oneOf(['light', 'dark']).isRequired,
  onChangeActiveTab: PropTypes.func.isRequired,
};

