import styled, {keyframes} from 'styled-components';
import {pulse} from 'react-animations'


const pulseAnimation = keyframes`${pulse}`


const rotateAnimation = keyframes`
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
`
export const MemojiWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Memoji = styled.img`
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

export const Container = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 4px solid rgb(255, 255, 255);
  background: rgba(152, 208, 255, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;

  &:hover {
    cursor: pointer;
    animation: ${pulseAnimation} 1s infinite;
  }

  ${Memoji}:hover & {
    cursor: pointer;
    animation: ${rotateAnimation} 1s infinite;
  }
`;

