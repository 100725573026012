import styled from "styled-components";
import {bigShadow, boxBackgroundColor} from "../../styled/GlobalStyle";
import {themes} from "../../styled/theme";
import theme from "styled-theming";


const {light, dark} = themes;
const cornerStroke = theme('mode', {
  light: dark.container,
  dark: light.container,
})

export const Container = styled.div`
  position: relative;
  border-radius: 32px;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  z-index:1;


  @media (max-width: 600px) {
    width:100%
  }
  
  a.mapboxgl-ctrl-logo {
    display: none !important;
  }

  .mapboxgl-ctrl-bottom-right {
    right: 0;
    bottom: 0;
    display: none;
  }
`;


export const ZoomOut = styled.div`
  background: ${boxBackgroundColor};
  box-shadow: ${props => props.mode === "dark" ? themes.dark.shadow : themes.light.shadow};
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  bottom: 14px;
  left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s ease 0s;
  will-change: transform;
  z-index: 1;

  &:hover {
    cursor: pointer;
    box-shadow: ${bigShadow};
    transition: 0.2s;
  }
`


export const Path = styled.path`
  stroke: ${cornerStroke};
`

export const ZoomIn = styled.div`
  background: ${boxBackgroundColor};
  box-shadow: ${props => props.mode === "dark" ? themes.dark.shadow : themes.light.shadow};
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  bottom: 14px;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s ease 0s;
  will-change: transform;
  z-index: 1;

  &:hover {
    cursor: pointer;
    box-shadow: ${bigShadow};
    transition: 0.2s;
  }
`;

