import {shadow, wrap} from '../../styled/GlobalStyle'
import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  opacity: ${({ active }) => (active ? 1 : 0.25)};
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 32px;
  box-shadow: ${shadow};
  background: ${wrap};

`;


export const imageStyle = css`
  position: absolute;
  height: 300px;
  transform: rotate(-32deg);
  left: 50px;
  top: 40px;
`


export const iPadStyle = css`
  top: 100px;
  position: absolute;
  height: 300px;
  transform: rotate(-32deg);
  left: 50px;
`


export const ujuzyStyles = css`
  position: absolute !important;
  right: -10px !important;
  top: 30px;
  transform: rotate(-32deg) !important;
  height: 13.5em !important;
  object-fit: cover !important;
`
