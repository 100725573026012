export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const translateX = (active) => {
  switch (active) {
    case "about": {
      return "translateX(3.6em)";
    }
    case "projects": {
      return "translateX(9em)";
    }
    case "media": {
      return "translateX(15.4em)";
    }
    default: {
      return "translateX(0%)";
    }
  }
};

export const transitionWidth = (active) => {
  switch (active) {
    case "about": {
      return "69px";
    }
    case "projects": {
      return "80px";
    }
    case "media": {
      return "70px";
    }
    default: {
      return "50px";
    }
  }
};

export const activeWidget = (layout, key) => {
  return !!layout.includes(key);
  // return 'all'
};

export const fetchJSON = (...args) =>
  fetch(...args).then((response) => {
    if (!response.ok) {
      throw new Error(
        `HTTP Error Response: ${response.status} ${response.statusText}`
      );
    }
    return response.json();
  });

