import propTypes from "prop-types";
import { Emoji } from "./styled";
import { Fragment } from "react";

export const Memoji = ({
  image,
  animated = false,
  width = 90,
  height = 105,
}) => {
  return (
    <Fragment>
      <Emoji
        animated={animated.toString()}
        src={image}
        width={width}
        height={height}
        alt={"memoji"}
        quality={70}
        loading="lazy"
      />
    </Fragment>
  );
};

Memoji.propTypes = {};
