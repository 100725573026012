import styled, {css, keyframes} from "styled-components";
import {border, color, padding} from "../../styled/GlobalStyle";
import {themes} from '../../styled/theme'
import theme from "styled-theming";

const {fonts, leading, light, dark, bigShadow, colors, spacing} = themes;


const wiggleAnimation = keyframes`
  0% {
    transform: translateX(1px,);
  }
  10% {
    transform: translateX(-1px);
  }
  20% {
    transform: translateX(-3px);
  }
  30% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(1px);
  }
  50% {
    transform: translateX(-1px);
  }
  60% {
    transform: translateX(-3px);
  }
  70% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(-1px);
  }
  90% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(1px, -2px);
  }

`

const shadows = theme('mode', {
  light: light.whiteShadow,
  dark: dark.shadow
});

const themedShadow = theme('mode', {
  light: colors.solitude,
  dark: colors.shark
})

export const Container = styled.div`
  padding: ${padding};
  position:relative;
  overflow: hidden;
`;

const cornerStroke = theme('mode', {
  light: dark.container,
  dark: light.container,
})

const lightPlaceholder = `
  &::placeholder {
    color: rgb(138, 148, 158);
  }
`

const darkPlaceholder = `
  &::placeholder {
    color: green;
  }
`

export const Header = styled.h2`
  font-size: 24px;
  font-family: "Moranga Bold", sans-serif;
  line-height: 32px;
  font-weight: 400;
  color: ${color};
  margin-bottom: 4px;
  margin-top: 7px;
`

export const Text = styled.p`
  font-size: ${fonts.lg}px;
  margin-top: 5px;
  color: ${color};
`;

export const Input = styled.input`
  outline: none !important;
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  width: 100%;
  color: ${color};
  background: transparent;
  border-bottom: 2px solid ${border};
  padding: 12px 0px;
  font-size: 16px;
  font-family: "Quicksand", system-ui;
  font-weight: 600;
  letter-spacing: 0.25px;
  font-weight: 400;
  margin-bottom: 8px;
  border-radius: 0px;
  ${({mode}) => mode === 'dark' ? darkPlaceholder : lightPlaceholder};
  animation: ${({error}) => error ? wiggleAnimation : 'none'} .5s infinite;

  @media (max-width: 800px) {
    margin: 5px 0 10px;
  }
`;

const disabledStyles = css`
  cursor: not-allowed !important;
`
const getButtonStatus = ({disabled}) => {
  return disabled ? disabledStyles : css``;
}

// rgb(201, 209, 217)
export const Button = styled.button`
  cursor: pointer;
  height: 36px;
  padding: 0 12px;
  top: 20px;
  right: 20px;
  border-radius: 18px;
  box-shadow: ${shadows};
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out 0s;
  will-change: box-shadow, transform;

  ${getButtonStatus}
  &:hover {
    cursor: pointer;
    transition: all 0.2s ease-out 0s;
    box-shadow: ${themedShadow} 0px 0px 0px 5px;
  }
`

export const ButtonText = styled.p`
  font-size: 14px;
  font-family: "Quicksand", system-ui;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${color};
  margin-left: 6px;
`;

export const CornerIcon = styled.svg`
  width: 18.256px;
  height: 18.256px;
`

export const Path = styled.g`
  stroke: ${cornerStroke};
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 25px;
`;

export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top:10px;
`;

export const Counter = styled.p`
  font-size: 14px;
  font-family: "Quicksand", system-ui;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: rgb(138, 148, 158);
`

export const Count = styled.span`
  font-family: "Moranga Bold", sans-serif;
  font-size: 24px;
  color: ${color};;
`


export const SVG = styled.svg`
position:absolute;
z-index: 0px;
left:0px;
`;

export const Content = styled.div`
margin-top: 20px;
`;
