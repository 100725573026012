import styled, {keyframes} from 'styled-components';
import {shadow} from "../../styled/GlobalStyle";
import theme from "styled-theming";
import {themes} from "../../styled/theme";

const {light} = themes;


const cornerBackgroundColor = theme('mode', {
  light: light.background,
  dark: 'rgb(33, 38, 45)',
})

const backgroundTheme = theme('mode',{
  light:'rgba(0, 0, 0, 0.04)',
  dark: 'rgb(33, 38, 45)',
})


const rotateNegativeAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
`

const rotateAnimation = keyframes`
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
`


export const Container = styled.div`
  height: 38px;
  width: 65px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  position: relative;
  background: ${backgroundTheme};
  box-shadow: ${shadow};

  &:hover {
    cursor: pointer;
  }
`;

export const Switch = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: rgb(13, 17, 23);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform .5s ease-out;
  transform: ${props => props.mode === 'light' ? 'translateX(15%)' : 'translateX(105%)'};
`


export const ToggleIcon = styled.svg`
  animation: ${props => props.mode === 'light' ? rotateAnimation : rotateNegativeAnimation} 1s;
`
