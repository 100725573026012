import React from 'react';
import ToggleButton from "../ToggleButton";
import PropTypes from 'prop-types';
import {Container} from "./styled";


export const ThemeSwitch = ({mode, onToggle}) => {
  return (
    <Container>
      <ToggleButton
        mode={mode}
        onToggle={onToggle}
      />
    </Container>
  );
};


ThemeSwitch.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  onToggle: PropTypes.func.isRequired,
}

