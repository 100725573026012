import styled, { css } from "styled-components";
import { themes } from "../../styled/theme";
import { shadow } from "../../styled/GlobalStyle";

const { border } = themes;

export const Container = styled.div`
  background-color: #0d1117 !important;
  height: 100%;
  width: 100%;
  border-radius: ${border.lg}px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${shadow};
  position: relative;
  overflow: hidden;
`;

export const Text = styled.h3`
  color: white;
  margin-bottom: 5em;
  text-align: center;
`;

export const ImageWrap = styled.div`
  position: absolute;
  bottom: -2px;
`;

export const SVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;

export const contentStyles = css`
  margin: 0 24px;
  top: -11px;
`;

export const arrowStyles = css`
  position: absolute;
  top: -45px;
  transform: rotate(185deg);
  right: 60px;
`;
