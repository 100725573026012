import React from 'react';
import {Artist, Container, Indicator, Line, Meta, NowPlaying, Spotify, Title, Typography} from "./styled";
import PropTypes from "prop-types";

export const Music = ({link, song, artists}) => {
  let newArtists = artists.length === 1 ? artists : artists.slice(0, 1);
  return (
    <Container>
      <Spotify src='assets/icons/spotify.svg' alt={'spotify'}/>
      <Meta>
        <NowPlaying>
          <Indicator>
            <Line/>
            <Line/>
            <Line/>
          </Indicator>
          <Typography>Offline. portfolio’s vibe</Typography>
        </NowPlaying>
        <Title target="_blank" href={link} rel="noreferrer">{song}</Title>
        {newArtists.map(({name}, index) =>
          <Artist key={index}>{name}</Artist>
        )}
      </Meta>
    </Container>
  );
};

Music.propTypes = {
  link: PropTypes.string.isRequired,
  song: PropTypes.string.isRequired,
  artists: PropTypes.array.isRequired
}

