import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import Home from '../views/Home';
import {useState} from 'react'
import axios from "axios";
import {validateEmail} from '../helper/helper'
import useSWR from 'swr'
import PropTypes from 'prop-types';
import {homeLayout} from '../layout';
import t from 'typy';
import _ from 'lodash';

const fetcher = url => axios.get(url).then(res => res.data)
const recentFetcher = url => axios.get(url).then(res => res.data)

const Index = ({toggleTheme, mode}) => {
  const [errorWiggle, setErrorMessage] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [toggled, setToggle] = useState(true)
  const [activeTab, setActiveTab] = useState('all')
  const subscribers = useSWR('api/subscribers', fetcher)
  const recent = useSWR('api/recent', recentFetcher)

  const onSetWiggle = () => {
    setErrorMessage(true)
    setTimeout(() => {
      setErrorMessage(false)
    }, 900)
  }

  const onToggle = _.debounce(()=>{
    setToggle(prev=>!prev)
  }, 250)

  const onSubmit = async () => {
    if (loading) return;


    if (!email || !email.length) {
      setLoading(false)
      return onSetWiggle()
    }

    let validatedEmail = validateEmail(email)

    if (!validatedEmail) {
      setLoading(false)
      return onSetWiggle()
    }

    setLoading(true)
    try {
      await axios.post("api/subscribe", {email})
      setLoading(false)
      setEmail('')
    } catch (e) {
      setLoading(false)
      onSetWiggle()
    }
  }

  const onChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const onChangeActiveTab = (tab) => {
    return setActiveTab(tab)
  }


  return (
    <Home
      recentPlayed={!t(recent?.data).isNullOrUndefined ? recent?.data[0].track : undefined}
      activeTab={activeTab}
      onToggle={onToggle}
      toggled={toggled}
      onChangeActiveTab={onChangeActiveTab}
      subscribers={subscribers?.data?.members?.length ? subscribers?.data?.members?.length + 50 : 0}
      error={errorWiggle}
      onSubmit={onSubmit}
      loading={loading}
      onChangeEmail={onChangeEmail}
      mode={mode}
      themeToggle={toggleTheme}
      layout={homeLayout[`${activeTab}`]}
      email={email}
    />
  )
}

Index.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['dark', 'light']).isRequired
}

export default Index;
