import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { CornerButton, CornerIcon, Path } from "./styled";

export const RoundButton = ({
  href,
  target = "_self",
  position,
  onUpdateDimensions,
}) => {
  const buttonRef = useRef();

  useEffect(() => {
    const updateDimensions = () => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        onUpdateDimensions(buttonRect); // Pass button dimensions to parent
      }
    };

    updateDimensions(); // Initial calculation
    window.addEventListener("resize", updateDimensions); // Recalculate on resize

    return () => window.removeEventListener("resize", updateDimensions); // Cleanup
  }, [onUpdateDimensions]);

  return (
    <CornerButton
      position={position}
      target={target}
      href={href}
      rel="noreferrer"
    >
      <CornerIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.3 18.3">
        <Path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M13 5l-8 8" />
          <path d="M8 5h5v6" />
        </Path>
        <path fill="none" d="M0 0h18v18H0z" />
      </CornerIcon>
    </CornerButton>
  );
};

RoundButton.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.oneOf(['_blank','_self'])
};
