import React, { useState, useEffect, useCallback } from "react";
import nookies from "nookies";
import styled from "styled-components";
import { useTour } from "../../context/TourContext/TourContext";

const ComponentWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const withTour = (WrappedComponent) => {
  const WithTour = (props) => {
    const [tourState, setTourState] = useState({
      isTourVisible: false,
    });
    const { isClosedAll, onCloseAllTours } = useTour(); // Use the shared context


    // Memoized mouse event handler
    const handleMouseToggle = useCallback(() => {
      setTourState((prev) => ({
        ...prev,
        isTourVisible: !prev.isTourVisible,
      }));
    }, []);

    const handleCloseTour = () => {
      setTourState((prev) => ({ ...prev, isTourVisible: false }));
    };

    const { isTourVisible } = tourState;

    return (
      <ComponentWrapper
        onMouseEnter={!isTourVisible ? handleMouseToggle : undefined}
        onMouseLeave={isTourVisible ? handleMouseToggle : undefined}
      >
        <WrappedComponent
          {...props}
          showOverlay={isTourVisible && !isClosedAll}
          onClose={handleCloseTour}
          onCloseAll={onCloseAllTours}
        />
      </ComponentWrapper>
    );
  };

  return WithTour;
};
