import styled, { css } from "styled-components";
import theme from "styled-theming";
import {themes} from "../../styled/theme";
import {shadow} from "../../styled/GlobalStyle";

const {dark, border} = themes


let linkedin =  "#0A66C2"


const backgroundColor = theme('mode', {
  light:linkedin,
  dark: dark.container
})


export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${shadow};
  border-radius: ${border.lg}px;
  background: ${backgroundColor};
`;


export const SVG = styled.svg`
position: absolute;
bottom:12px;
right:12px;
`

export const contentStyles = css`
  position: absolute;
  bottom: 30px;
`;

export const arrowStyles = css`
  margin-left: 46px;
  margin-top: 2px;
`;


export const DismissTourStyles = css`
  top: 18px;
`;


