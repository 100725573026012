import styled from "styled-components";
import {color} from "../../styled/GlobalStyle";


export const Container = styled.nav`
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 4vw;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    height: 180px;
  }
`;

export const LogoWrap = styled.div``

export const Logo = styled.img``

export const Switcher = styled.div`
  font-size: 14px;
  font-family: "Silka Medium", sans-serif;
  line-height: 24px;
  letter-spacing: 0.25px;
  font-weight: 400;
  color: ${color};

  @media (max-width: 600px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.25s ease 0s;
  }
`;
