import styled, { css, keyframes } from "styled-components";
import { color, padding } from "../../styled/GlobalStyle";
import { themes } from "../../styled/theme";
import theme from "styled-theming";
import { wrap } from "../../styled/GlobalStyle";

const { fonts, leading, border, colors, light, dark } = themes;

const Pulse = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0.75;
  }
  100% {
    transform: scale(1.35);
    opacity: 0;
  }
`;
const rotatePositive = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const rotateNegative = keyframes`
  0% {
    transform: rotate(-0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
`;

const themedShadow = theme("mode", {
  light: colors.solitude,
  dark: colors.shark,
});

const shadows = theme("mode", {
  light: light.whiteShadow,
  dark: dark.shadow,
});

const iconFill = theme("mode", {
  light: colors.white,
  dark: colors.cyan,
});

const cornerStroke = theme("mode", {
  light: dark.container,
  dark: light.container,
});

export const Container = styled.div`
  border-radius: ${border.lg}px;
  overflow: hidden;
  position: relative;
`;

export const ContentContainer = styled.div`
  padding: ${padding};
`;

export const Text = styled.p`
  font-size: ${fonts.lg}px;
  line-height: ${leading.lg}px;
  font-weight: 400;

  color: ${color};
`;

export const Header = styled.h3`
  font-family: Moranga Bold, sans-serif;
  color: ${color};
`;

const disabledStyles = css`
  cursor: not-allowed !important;
`;
const getButtonStatus = ({ disabled }) => {
  return disabled ? disabledStyles : css``;
};

// rgb(201, 209, 217)
export const Button = styled.button`
  cursor: pointer;
  box-shadow: ${shadows};
  position: absolute;
  height: 36px;
  padding: 0 12px;
  top: 20px;
  right: 20px;
  border: none;
  border-radius: 18px;
  background: ${wrap};
  display: flex;
  align-items: center;
  transition: all 0.2s ease-out 0s;
  will-change: box-shadow, transform;
  z-index: 100;

  ${getButtonStatus}
  &:hover {
    cursor: pointer;
    transition: all 0.2s ease-out 0s;
    box-shadow: ${themedShadow} 0px 0px 0px 5px;
  }
`;

export const ToggleIcon = styled.svg`
  width: 15.702px;
  height: 15.702px;
  fill: ${iconFill};
`;

export const TogglePath = styled.path`
  stroke: ${cornerStroke};
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation-delay: 200s;
  transform: rotate(180deg) translateZ(0px);
  animation: ${({ shouldRotate }) =>
      shouldRotate ? rotateNegative : rotatePositive}
    0.5s ease-in-out;
`;

const animation = keyframes`
  0% {
    background-color: rgb(250, 209, 217);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.95);
    opacity: 1;
    background-color: rgb(250, 209, 217);
  }
  100% {
    transform: scale(4);
    opacity: 0;
    background-color: transparent;
  }
`;

const animationReverse = keyframes`
  0% {
    background-color: rgb(250, 209, 217);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.95);
    opacity: 1;
    background-color: rgb(250, 209, 217);
  }
  100% {
    transform: scale(4);
    opacity: 0;
    background-color: transparent;
  }

`;

export const Ripple = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  top: 30px;
  animation: ${({ shouldRotate }) =>
      shouldRotate ? animation : animationReverse}
    0.5s ease-in 5ms;
`;

export const ButtonText = styled.span`
  font-size: ${fonts.base}px;
  font-family: "Quicksand", system-ui;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${color};
  margin-left: 6px;
`;

export const ImageContainer = styled.div`
  position: relative;
  z-index: -1;
`;

export const contentStyles = css`
  position: relative;
  z-index: 2;
  color: white;
  margin: 0 20px 0 182px;
  top: -12px;
`;

export const arrowStyles = css`
  fill: white;
  position: absolute;
  top: -40px;
  transform: rotate(200deg);
  left: 122px;
`;
