import React from "react";
import {Container, Switch, ToggleIcon} from "./styled";
import PropTypes from "prop-types";

export const ToggleButton = ({mode, onToggle}) => {

  return (
    <Container onClick={onToggle}>
      <Switch
        mode={mode}
        // style={mode === 'light' ? styles.switchLeft : styles.switchRight}
      >
        {mode === 'light'
          ? <ToggleIcon
            // style={{  transform: 'none',  transformOrigin: '12px 11.9991px'}}
            mode={mode}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="18"
            viewBox="0 0 16.261 19.073">
            <path
              d="M9,19c3.187.18,5.843-.829,7.611-3.487a.41.41,0,0,0,0-.513c-.217-.225-.611,0-.611,0A7.433,7.433,0,1,1,11,1s.591-.1.561-.524S11,0,11,0A9.552,9.552,0,1,0,9,19Z"
              transform="translate(-0.447 0.053)" fill="#ffe3a4"/>
          </ToggleIcon>
          : <ToggleIcon
            // style={{  transform: 'none',  transformOrigin: '12px 11.9991px'}}
            mode={mode}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24">
            <path
              d="M12,0a.945.945,0,0,0-1,1V2a1,1,0,0,0,2,0V1A.945.945,0,0,0,12,0ZM4.2,3.2a.986.986,0,0,0-.7.3.967.967,0,0,0,0,1.4l.7.7A.99.99,0,0,0,5.6,4.2l-.7-.7A.991.991,0,0,0,4.2,3.2Zm15.6,0a.991.991,0,0,0-.7.3l-.7.7a.99.99,0,0,0,1.4,1.4l.7-.7a.967.967,0,0,0,0-1.4A.986.986,0,0,0,19.8,3.2ZM12,5a7,7,0,1,0,7,7,7,7,0,0,0-7-7ZM1,11a1,1,0,0,0,0,2H2a1,1,0,0,0,0-2Zm21,0a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2ZM4.9,18.1a.991.991,0,0,0-.7.3l-.7.7a.99.99,0,0,0,1.4,1.4l.7-.7a.967.967,0,0,0,0-1.4A.986.986,0,0,0,4.9,18.1Zm14.2,0a.986.986,0,0,0-.7.3.967.967,0,0,0,0,1.4l.7.7a.99.99,0,0,0,1.4-1.4l-.7-.7A.991.991,0,0,0,19.1,18.1ZM12,21a.945.945,0,0,0-1,1v1a1,1,0,0,0,2,0V22A.945.945,0,0,0,12,21Z"
              fill="#ffe3a4"
            />
          </ToggleIcon>
        }
      </Switch>
    </Container>
  );
}


const styles = {
  switchLeft: {
    transformOrigin: '50% 50% 0px',
    left: '6px'
  },
  switchRight: {
    transformOrigin: '50% 50% 0px',
    right: '6px'
  }
}


ToggleButton.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  onToggle: PropTypes.func.isRequired,
};