import styled, { css, keyframes } from "styled-components";
import { border, color, shadow, wrap } from "../../styled/GlobalStyle";
import { themes } from "../../styled/theme";
import theme from "styled-theming";

export const Header = styled.h3`
  font-weight: 400;
  white-space: nowrap;
  color: #ede9e9;
`;

export const Text = styled.p`
  color: #ede9e9;
  font-size: ${themes.fonts.lg}px;
  margin-top: 6px;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  bottom: 50px;
`;

const backgroundColor = theme("mode", {
  light: "#cd5313",
  dark: themes.dark.container,
});

export const Container = styled.div`
  position: relative;
  border: ${border};
  overflow: hidden !important;
  height: 100%;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 32px;
  background: ${backgroundColor};
  box-shadow: ${shadow};
  overflow: hidden !important;
`;

export const CoverImage = styled.img`
  position: absolute;
  right: -184px;
  top: 72px;
  transform: rotate(0deg);
  height: 70%;
  object-fit: cover;
`;

export const SVG = styled.svg`
  position: absolute;
  top: -27px;
  right: -25px;
  transform: rotate(-21deg);
`;

export const contentStyles = css`
  position: absolute;
  bottom: 40px;
`;

export const arrowStyles = css`
  margin-left: 27px;
  margin-top: 8px;
`;

export const DismissTourStyles = css`
  top: 18px;
`;
