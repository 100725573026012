import React, {useEffect, useRef, useState} from "react";
import {Marker, StaticMap} from "react-map-gl";
import {Container, Path, ZoomIn, ZoomOut} from "./styled";
import CustomMarker from '../CustomMarker';
import PropTypes from 'prop-types';
import 'mapbox-gl/dist/mapbox-gl.css'

let zoom = 12

export const Map = ({mode, memoji, animated}) => {
  const map = useRef(null);
  const [value, setValue] = useState(zoom)
  const [mapViewport, setMapViewport] = useState({
    height: "100%",
    width: "100%",
    longitude: 36.797531,
    latitude: -1.321875,
    zoom: zoom
  });

  useEffect(() => {
    if (!map.current) {
      map.current.getMap().coveringZoomLevel(6)
    }
  }, [])

  const onZoomOut = () => {
    map.current.getMap().zoomOut(5)
      setValue(map.current.getMap().getZoom())
    }

    const onZoomIn = () => {
      setValue(map.current.getMap().getZoom())
      map.current.getMap().zoomIn(5)
    }

    return (
      <Container>
        <StaticMap
          scrollZoom={false}
          dragPan={false}
          dragRotate={false}
          doubleClickZoom={false}
          touchZoom={false}
          touchRotate={false}
          keyboard={false}
          maxZoom={13}
          minZoom={8}
          ref={map}
          zoom={zoom}
          {...mapViewport}
          mapboxApiAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
          mapStyle={mode === "dark" ? "mapbox://styles/toel/ckrv3b45oa5rs19o1ygl863yk" : "mapbox://styles/toel/ckrv3irbn5bf518mniqyuorwv"}
          onViewportChange={setMapViewport}
        >
          <Marker latitude={-1.321875} longitude={36.797531} offsetLeft={-50} offsetTop={-55}>
            <CustomMarker
              animated={animated}
              memoji={memoji}
            />
          </Marker>
        </StaticMap>
        {
          value >= 8 &&
          <ZoomOut mode={mode} onClick={onZoomOut}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <Path
                d="M16,12H8"
                fill="none"
                stroke="#F0F2F8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </ZoomOut>
        }

        {
          value < zoom &&
          <ZoomIn mode={mode} onClick={onZoomIn}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <Path
                d="M12,8v8"
                fill="none"
                stroke="#F0F2F8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <Path
                d="M16,12H8"
                fill="none"
                stroke="#F0F2F8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </ZoomIn>
        }
      </Container>
    );
  }
;

Map.propTypes =
  {
    mode: PropTypes.oneOf(['dark', 'light']).isRequired
  }
;

