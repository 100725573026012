import React from "react";
import { Container, Logo, LogoWrap, Switcher } from "./styled";
import NavTabs from "../NavTabs";
import PropTypes from "prop-types";
import ThemeSwitch from "../Theme";

export const NavigationBar = ({
  activeTab,
  mode,
  onChangeActiveTab,
  onToggle,
}) => {
  return (
    <Container>
      <LogoWrap>
        <Logo
          width={47}
          height={60}
          srcset="/images/logo.svg 150w, /images/logo.svg 2000w, /images/logo.svg 3000w"
          src={"/images/logo.svg"}
          alt={"to-logo"}
        />
      </LogoWrap>
      <NavTabs
        mode={mode}
        activeTab={activeTab}
        onChangeActiveTab={onChangeActiveTab}
      />
      <Switcher>
        <ThemeSwitch mode={mode} onToggle={onToggle} />
      </Switcher>
    </Container>
  );
};

NavigationBar.propTypes = {
  activeTab: PropTypes.oneOf(["all", "about", "projects", "media"]).isRequired,
  mode: PropTypes.oneOf(["light", "dark"]).isRequired,
  onChangeActiveTab: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};
