import styled, { css } from "styled-components";
import { themes } from "../../styled/theme";
import theme from "styled-theming";
import { wrap } from "../../styled/GlobalStyle";

const {
  dark,
  light,
  colors: { solitude, shark },
} = themes;

const shadows = theme("mode", {
  light: "transparent",
  dark: shark,
});

const cornerStroke = theme("mode", {
  light: dark.container,
  dark: light.container,
});

const themedShadow = theme("mode", {
  light: solitude,
  dark: shark,
});

export const CornerIcon = styled.svg`
  width: 18.256px;
  height: 18.256px;
`;

export const Path = styled.g`
  stroke: ${cornerStroke};
`;

const defaultPosition = css`
  bottom: 14px;
  left: 14px;
`;
export const CornerButton = styled.a`
  background: ${wrap};
  box-shadow: ${shadows} 0px 0px 0px 2px inset;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  position: absolute;
  ${({ position }) => (position ? position : defaultPosition)}
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  will-change: box-shadow, transform;
  pointer-events: auto;
  z-index: 1000;

  &:hover {
    cursor: pointer;
    box-shadow: ${themedShadow} 0px 0px 0px 5px;
    transition: all 0.2s ease-out 0s;
  }
`;
