import PropTypes from "prop-types";
import { Responsive, WidthProvider } from "react-grid-layout";
import React, { Fragment, useEffect, useState } from "react";
import Head from "next/head";
import About from "../../components/About";
import {
  AnimatedContainer,
  AnimatedFadeInContainer,
  GridContainer,
} from "../../styled/GlobalStyle";
import Map from "../../components/Map";
import { imageStyle, ujuzyStyles, Wrapper } from "./styled";
import LinkedIn from "../../components/LinkedIn";
import Music from "../../components/Music";
import Newsletter from "../../components/Newsletter";
import Flutterwave from "../../components/Flutterwave";
import Cruuunchify from "../../components/Cruuunchify";

import NavigationBar from "../../components/NavigationBar";
import { activeWidget } from "../../helper/helper";
import t from "typy";
import modern from "../../../public/assets/memoji/me-1.webp";
import past from "../../../public/assets/memoji/me-2.webp";
import geek from "../../../public/assets/memoji/geek.webp";

import Resume from "../../components/Resume";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Home = ({
  onToggle,
  toggled,
  recentPlayed,
  layout,
  activeTab,
  onChangeActiveTab,
  error,
  email,
  subscribers,
  onSubmit,
  loading,
  onChangeEmail,
  mode,
  themeToggle,
}) => {
  let music = t(recentPlayed).isNullOrUndefined ? defaultMusic : recentPlayed;

  let memoji = toggled ? modern : past;

  const [animated, setAnimated] = useState(false);
  const [col, setCol] = useState("md");
  const [size, setSize] = useState();
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    setSize(window.screen.width);
    if (window.screen.width <= 768) {
      setCol("sm");
    } else {
      setCol("md");
    }
  }, []);

  const onToggleAnimation = () => {
    onToggle();
    return setAnimated((prev) => !prev);
  };

  const breakPointChange = (newBreakpoint, newCols) => {
    setCol(newBreakpoint);
  };

  return (
    <Fragment>
      <Head>
        <title>Apiut Toel | Product Designer | UX Portfolio</title>
        <meta
          name="description"
          content="Apiut Toel, a skilled Senior Product Designer from Kenya, specializes in crafting intuitive and visually appealing digital experiences for SaaS products. His portfolio showcases a passion for user-centered design, delivering solutions that seamlessly blend functionality and aesthetics."
        />
        <meta
          name="keywords"
          content="Apiut Toel, Senior Product Designer Designer, Kenya, SaaS Design, User Experience, User Interface, Product Design, Digital Design, Nairobi, App Design, Web Design, UI/UX Portfolio, User-Centered Design, Intuitive Design, Visually Appealing"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />

        {/* Canonical */}
        <link rel="canonical" href="https://www.toel.me" />

        <link
          rel="icon"
          type="image/png"
          href="https://www.toel.me/images/favicon.png"
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:site_name"
          content="Apiut Toel | Senior Product Designer | Specializing in SaaS products"
        />
        <meta
          property="og:title"
          content="Apiut Toel | Senior Product Designer"
        />
        <meta
          property="og:description"
          content="Apiut Toel, a skilled Product Designer from Kenya, specializes in crafting intuitive and visually appealing digital experiences for SaaS products."
        />
        <meta property="og:url" content="https://www.toel.me" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.toel.me/assets/brand/brand_cover.webp"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Apiut Toel | Senior Product Designer | Specializing in SaaS products"
        />
        <meta
          name="twitter:description"
          content="Apiut Toel, a skilled Senior Product Designer from Kenya, specializes in crafting intuitive and visually appealing digital experiences for SaaS products."
        />
        <meta
          name="twitter:image"
          content="https://www.toel.me/assets/brand/brand_cover.webp"
        />

        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": "Apiut Toel",
        "jobTitle": "Senior Product Designer",
        "url": "https://www.toel.me",
        "sameAs": [
          "https://www.linkedin.com/in/toelapiut",
          "https://www.behance.net/apiuttoel"
        ],
        "image": "https://www.toel.me/assets/brand/brand_cover.webp",
        "description": "Apiut Toel, a skilled Senior Product Designer from Kenya, specializes in crafting intuitive and visually appealing digital experiences for SaaS products."
      }
    `}
        </script>
      </Head>
      <AnimatedFadeInContainer>
        <NavigationBar
          mode={mode}
          onToggle={themeToggle}
          activeTab={activeTab}
          onChangeActiveTab={onChangeActiveTab}
        />
      </AnimatedFadeInContainer>
      <AnimatedContainer>
        <GridContainer>
          <ResponsiveGridLayout
            onDragStart={handleDragStart}
            onDragStop={handleDragStop}
            margin={[20, 20]}
            useCSSTransforms={true}
            isResizable={false}
            onBreakpointChange={breakPointChange}
            className="layout"
            layouts={layout}
            isDraggable={toggled}
            measureBeforeMount={false}
            rowHeight={130}
            breakpoints={{ md: 996, sm: 768 }}
            cols={{ md: 4, sm: 4 }}
          >
            <Wrapper active={activeWidget(layout.active, "bio")} key="bio">
              <About
                animated={animated}
                onToggleAnimation={onToggleAnimation}
                onToggle={onToggle}
                toggled={toggled}
                memoji={memoji}
              />
            </Wrapper>
            <Wrapper active={activeWidget(layout.active, "map")} key="map">
              <Map animated={animated} memoji={geek} mode={mode} />
            </Wrapper>
            <Wrapper
              active={activeWidget(layout.active, "resume")}
              key="resume"
            >
              <Resume />
            </Wrapper>
            <Wrapper active={activeWidget(layout.active, "music")} key="music">
              <Music
                artists={music.artists}
                link={music.external_urls.spotify}
                song={music.name}
              />
            </Wrapper>
            <Wrapper
              active={activeWidget(layout.active, "linkedin")}
              key="linkedin"
            >
              <LinkedIn
                title="Curious about me?"
                description="Click here to check out my LinkedIn profile!"
              />
            </Wrapper>
            <Wrapper
              active={activeWidget(layout.active, "flutterwave")}
              key="cruuunchify"
            >
              <Flutterwave
                mode={mode}
                buttonTitle={""}
                image={""}
                backgroundImage={""}
              />
            </Wrapper>

            <Wrapper
              active={activeWidget(layout.active, "flutterwave")}
              key="flutterwave"
            >
              <Cruuunchify
                link={"flutterwave"}
                imageStyle={imageStyle}
                mode={mode}
                buttonTitle={""}
                image={""}
                backgroundImage={""}
              />
            </Wrapper>
            <Wrapper
              active={activeWidget(layout.active, "newsletter")}
              key="newsletter"
            >
              <Newsletter
                email={email}
                error={error}
                loading={loading}
                onChangeEmail={onChangeEmail}
                onSubmit={onSubmit}
                totalSubscribers={subscribers}
              />
            </Wrapper>
          </ResponsiveGridLayout>
        </GridContainer>
      </AnimatedContainer>
    </Fragment>
  );
};

let defaultMusic = {
  artists: [
    {
      name: "Dr. Saxlov",
    },
  ],
  external_urls: {
    spotify:
      "https://open.spotify.com/track/2h0PvFOi0dn4vrfVZniFe9?si=d6b94c640cd74da2",
  },
  name: "Tears in Heaven Version",
};

Home.propTypes = {
  layout: PropTypes.object.isRequired,
  recentPlayed: PropTypes.object,
  themeToggle: PropTypes.func.isRequired,
  onChangeActiveTab: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  subscribers: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  activeTab: PropTypes.oneOf(["all", "about", "projects", "media"]).isRequired,
  mode: PropTypes.oneOf(["light", "dark"]).isRequired,
};
