import styled, {css, keyframes} from "styled-components";
import Image from "next/image";

const  animation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  25% {
    transform: rotate(15deg) scale(.75);
    opacity: 0.75;
  }
  50% {
    transform: rotate(25deg) scale(.5);
    opacity: 0.5;
  }
  75% {
    transform: rotate(35deg) scale(.25);
    opacity: 0.25;
  }
  100% {
    transform: rotate(45deg) scale(0);
    opacity: 0;
  }
`;

const animationReverse = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  25% {
    transform: rotate(15deg) scale(.75);
    opacity: 0.75;
  }
  50% {
    transform: rotate(25deg) scale(.5);
    opacity: 0.5;
  }
  75% {
    transform: rotate(35deg) scale(.25);
    opacity: 0.25;
  }
  100% {
    transform: rotate(45deg) scale(0.1);
    opacity: 0;
  }
`;

export const Emoji = styled(Image)`
  height: auto;
  position: relative;
  z-index: 10;
  animation: ${({ animated }) => (animated ? animation : animationReverse)} 0.5s
    ease-in-out 10ms;
`;