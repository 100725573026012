import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  Container,
  Header,
  Button,
  ButtonIcon,
  ButtonText,
  ImageContainer,
  ToggleIcon,
  TogglePath,
  Ripple,
  contentStyles,
  arrowStyles,
  ContentContainer,
} from "./styled";
import Memoji from "../Memoji";
import Tour from "../Tour";

export const About = ({
  toggled,
  animated,
  memoji,
  onToggleAnimation,
  showOverlay,
  onCloseAll,
}) => {
  const [buttonDimensions, setButtonDimensions] = useState(null);

  const buttonRef = useRef();

  useEffect(() => {
    const updateDimensions = () => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        setButtonDimensions(buttonRect); // Pass button dimensions to parent
      }
    };

    updateDimensions(); // Initial calculation
    window.addEventListener("resize", updateDimensions); // Recalculate on resize

    return () => window.removeEventListener("resize", updateDimensions); // Cleanup
  }, [buttonDimensions]);

  return (
    <Container>
      {showOverlay && (
        <Tour
          onDismissTour={onCloseAll}
          contentStyles={contentStyles}
          arrowStyles={arrowStyles}
          buttonDimensions={buttonDimensions}
          title="Mix it up!"
          description="Customize this page just like you would an Apple widget - drag and drop to rearrange elements, or lock it in place to keep everything tidy."
        />
      )}
      <ContentContainer>
        <ImageContainer>
          <Memoji animated={animated} image={memoji} />
          {/*<Ripple shouldRotate={toggled}/>*/}
        </ImageContainer>
        <div>
          <Header>Hey, Welcome!</Header>
          <Text>
            I’m a Product Designer passionate about creating meaningful change
            through collaboration. My goal is to make life better for people
            with thoughtful, impactful design.
          </Text>
        </div>

        <Button onClick={onToggleAnimation}>
          <ButtonIcon shouldRotate={toggled}>
            <ToggleIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15.702 15.702"
            >
              <g transform="translate(2.62 2.617)">
                <TogglePath
                  d="M14.8,12.032a5.229,5.229,0,0,1-9.824,2.482"
                  transform="translate(-4.34 -6.777)"
                  fill="none"
                  stroke="#0D1117"
                  strokeLinecap="round"
                  strokeLinejoin={"round"}
                  strokeWidth="1.4"
                />
                <TogglePath
                  d="M4,9.149A5.231,5.231,0,0,1,13.83,6.731"
                  transform="translate(-4.004 -4)"
                  fill="none"
                  stroke="#0D1117"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.4"
                />
                <TogglePath
                  d="M15.953,6.952h2.313V4.639"
                  transform="translate(-8.135 -4.221)"
                  fill="none"
                  stroke="#0D1117"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.4"
                />
                <TogglePath
                  d="M6.825,15.825H4.512v2.313"
                  transform="translate(-4.18 -8.088)"
                  fill="none"
                  stroke="#0D1117"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.4"
                />
              </g>
              <path
                id="Path_9"
                data-name="Path 9"
                d="M0,0H15.7V15.7H0Z"
                fill="none"
              />
            </ToggleIcon>
          </ButtonIcon>
          <ButtonText>{toggled ? " Enable" : "Disable"} Lockdown</ButtonText>
        </Button>
      </ContentContainer>
    </Container>
  );
};
