import styled, { css, keyframes } from "styled-components";
import theme from "styled-theming";
import { themes } from "../../styled/theme";

const {
  light,
  dark,
  colors: { solitude, shark },
} = themes;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const themedShadow = theme("mode", {
  light: solitude,
  dark: shark,
});

const shadows = theme("mode", {
  light: light.transWhite,
  dark: dark.shadow,
});

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 17, 23, 0.98); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: clip-path 0.3s ease;
  clip-path: ${({ clipPath }) => clipPath || "none"}; /* Dynamic clip-path */
  animation: ${fadeIn} 350ms ease-in;
  opacity: 1;
  transition: opacity 350ms ease-in;
  z-index: 100;
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  color: white;
  margin: 18px;

  ${({ contentStyles }) =>
    contentStyles ||
    css`
      max-width: 300px;
    `}
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  color: white;
`;

export const Text = styled.p`
  font-size: 14px;
  color: white;
`;

export const SVG = styled.svg`
  fill: white;
  ${({ arrowStyles }) => arrowStyles}
`;

export const DismissTour = styled.button`
  border: none;
  background: no-repeat;
  color: white;
  position: absolute;
  left: 14px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 14px;
  cursor: pointer;
  border-radius: 18px;
  box-shadow: ${shadows};

  ${({ DismissTourStyles }) =>
    DismissTourStyles ||
    css`
      bottom: 18px;
    `}

  &:hover {
    cursor: pointer;
    box-shadow: ${themedShadow} 0px 0px 0px 5px;
    transition: all 0.2s ease-out 0s;
  }
`;
