export const homeLayout = {
  all: {
    // sm: [],
    md: [
      { i: "bio", x: 0, y: 0, w: 2, h: 2 },
      { i: "map", x: 2, y: 0, w: 1, h: 2 },
      { i: "resume", x: 3, y: 0, w: 1, h: 2 },
      { i: "music", x: 0, y: 1, w: 1, h: 2 },
      { i: "linkedin", x: 1, y: 1, w: 1, h: 2 },
      { i: "cruuunchify", x: 2, y: 1, w: 1, h: 4 },
      { i: "flutterwave", x: 3, y: 1, w: 1, h: 4 },
      { i: "newsletter", x: 0, y: 2, w: 2, h: 2 },
    ],
    active: [
      "bio",
      "map",
      "resume",
      "music",
      "linkedin",
      "cruuunchify",
      "flutterwave",
      "newsletter",
    ],
  },
  about: {
    md: [
      { i: "bio", x: 0, y: 0, w: 2, h: 2 },
      { i: "map", x: 1, y: 1, w: 1, h: 2 },
      { i: "resume", x: 3, y: 0, w: 1, h: 2 },
      { i: "music", x: 0, y: 1, w: 1, h: 2 },
      { i: "linkedin", x: 2, y: 0, w: 1, h: 2 },
      { i: "cruuunchify", x: 2, y: 1, w: 1, h: 4 },
      { i: "flutterwave", x: 3, y: 1, w: 1, h: 4 },
      { i: "newsletter", x: 0, y: 2, w: 2, h: 2 },
    ],
    // sm: [],
    active: ["bio", "map", "resume", "music", "linkedin"],
  },
  projects: {
    md: [
      { i: "bio", x: 2, y: 3, w: 2, h: 2 },
      { i: "map", x: 1, y: 1, w: 1, h: 2 },
      { i: "newsletter", x: 2, y: 0, w: 2, h: 2 },
      { i: "resume", x: 2, y: 1, w: 1, h: 2 },
      { i: "music", x: 0, y: 1, w: 1, h: 2 },
      { i: "linkedin", x: 3, y: 0, w: 1, h: 2 },
      { i: "cruuunchify", x: 0, y: 0, w: 1, h: 4 },
      { i: "flutterwave", x: 1, y: 0, w: 1, h: 4 },
    ],
    // sm: [],
    active: ["cruuunchify", "flutterwave", "newsletter"],
  },
};

export const homeCols = { lg: 0, md: 4, sm: 2, xs: 2, xxs: 2 }
