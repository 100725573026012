import styled from "styled-components";
import theme from 'styled-theming'
import {translateX, transitionWidth} from "../../helper/helper";
import {boxBackgroundColor, color, wrap} from "../../styled/GlobalStyle";
import {themes} from "../../styled/theme";



const backgroundTheme = theme('mode',{
  light:'rgba(0, 0, 0, 0.04)',
  dark: 'transparent',
})

const highlightTheme = theme('mode', {
  light: '#fff',
  dark:'rgb(33, 38, 45)'
})
// rgb(33, 38, 45)
export const Container = styled.div`
  position: relative;
  background: ${backgroundTheme};
  border-radius: 23px;
  font-family: "Quicksand", system-ui;
  font-weight: 600;
  transform: translateX(0px);
  display: flex;
  font-size: 14px;
  padding: 5px;
  border: 2px solid
    ${({ mode }) => (mode === "dark" ? "rgb(48, 54, 61)" : "tranparent")};
`;


export const Highlight = styled.div`
  position: absolute;
  height: 32px;
  width: ${({ activeTab }) => transitionWidth(activeTab)};
  border-radius: 16px;
  background: ${highlightTheme};
  z-index: -1;
  left: ${({ activeTab }) => (activeTab === "all" ? "6px" : "3px")};
  transition: transform 0.5s ease-out, width 0.5s ease-out;
  transform: ${({ activeTab }) => translateX(activeTab)};
`;

export const Options = styled.div`
  border-radius: 50px;
  display: flex;
  color: ${color};
  text-transform: capitalize;
  align-items: center;
  height: 32px;
  padding: 0px 16px;
  transition: opacity 0.3s ease 0s;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.25s ease 0s;
  }
`