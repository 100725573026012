import styled, {keyframes} from 'styled-components';

import {color, padding} from "../../styled/GlobalStyle";
import {themes} from "../../styled/theme";


const equalizerAnimation = keyframes`
  0% {
    height: 3px;
  }
  25% {
    height: 7px;
  }
  50% {
    height: 8px;
  }
  75% {
    height: 11px;
  }
  100% {
    height: 14px;
  }
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${padding};
  border-radius: ${themes.border.lg}px;
  @media (max-width: 800px) {
    padding: 30px 30px;
  }
`

export const Spotify = styled.img`
  width: 74px;
  height: 74px;
  margin-left: -4px;

  @media (max-width: 800px) {
    width: 60px;
    height: 60px;
  }
`

export const Meta = styled.div`
`;


export const NowPlaying = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`

export const Typography = styled.p`
  font-size: 14px;
  font-family: "Quicksand", system-ui;
  font-weight: 600;
  line-height: 24px;
  color: rgb(110, 210, 183);
  margin: 0;
`

export const Indicator = styled.div`
  height: 20px;
  margin-right: 4px;
  position: relative;
  display: flex;
  align-items: center;
`

export const Line = styled.div`
  width: 3px;
  height: 3px;
  margin-right: 3px;
  background: rgb(110, 210, 183);
  border-radius: 1.5px;
  animation: ${equalizerAnimation} 500s infinite alternate;

  &:nth-child(1) {
    animation-duration: 690ms;
  }

  &:nth-child(2) {
    animation-duration: 850ms;
  }

  &:nth-child(3) {
    animation-duration: 740ms;
  }

`;

export const Title = styled.h3`
  margin: 0;
  font-weight: 400;
  color: ${color};
  transition: opacity 0.5s ease 0s;
  display: inline-block;
  text-decoration: none;

  @media (max-width: 800px) {
    font-size: 32px;
    line-height: 38px;
    margin: 6px 0px;
  }
`;


export const Artist = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${color};
`;