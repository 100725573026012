import React from 'react';
import {Container, MemojiWrapper} from './styled';
import Memoji from "../Memoji";

export const CustomMarker = ({memoji, animated}) => {
  return (
    <Container>
      <MemojiWrapper>
        <Memoji
          width={43}
          height={48}
          image={memoji}
          animated={animated}
        />
      </MemojiWrapper>
    </Container>
  );
};

CustomMarker.propTypes = {

};

